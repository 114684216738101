export const business = [{
        path: '/BusinessHome',
        name: 'BusinessHome',
        component: () => import('@/views/business/homeIndex/homeIndex.vue'),
    },
    {
        path: '/appStore',
        name: 'appStore',
        title: '全部应用商品',
        component: () => import('@/views/business/commodity/index.vue')
    },
    {
        path: '/commodityInfo',
        name: 'commodityInfo',
        title: '商品详情',
        component: () => import('@/views/business/commodity/commodityInfo.vue')
    },
    {
        path: '/pay',
        name: 'pay',
        title: '商品详情',
        component: () => import('@/views/business/commodity/pay.vue')
    },
    {
        path: '/temlateLook',
        name: 'TemlateLook',
        component: resolve => require(['@/views/business/temlateLook/index'], resolve)
    },
    // 招标列表页
    {
        path: '/messageList',
        name: 'MessageList',
        component: resolve => require(['@/views/business/messageList/index'], resolve)
    },
]