export const looktype = {
    data() {
        return {
            looktype: 0
        }
    },
    created() {
        this.looktype = document.body.clientWidth >= 750 ? 1 : 2;
        window.onresize = () => {
            this.looktype = document.body.clientWidth >= 750 ? 1 : 2;
        };
    }
}