import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import {
	dark,
	light
} from './theme';

export default new Vuex.Store({
	state: {
		choseType: 0,  // 初始化的Type值
		userInfo: {
			mobile: '',
			email: '',
			avatar: '',
		},
		loginInfo: {
			company: {},
			staff: {},
		},
		nickname: '',
		pageNav: [],
		//商城 store --------
		cid: 0,
		customData: null,
		includes: "searchList",
		name_logo: {
			name: "",
			logo: "",
			favicon: "",
		},
		finger:false,
	},
	mutations: {
		changeFinger(state, val){
			state.finger = val;
		},
		changeThemes(state, val) {
			if (val == '1') {
				for (let keys in light) {
					document.body.style.setProperty(keys, light[keys]);
				}
			} else if (val == '2') {
				for (let keys in dark) {
					document.body.style.setProperty(keys, dark[keys]);
				}
			}
		},
		updatePageNav(state, val) {
			state.pageNav = val;
		},
		loginInfo(state, val) {
			state.loginInfo = val;
		},
		changeChoseType(state, val) {
			state.choseType = val;  // 修改type值
		},
		updateUserInfo(state, val) {
			for (let key in val) {
				state.userInfo[key] = val[key];
			}
		},
		getClientHeight: (state, data) => { // 获取当前浏览器高度,宽度
			state.clientHeight = data;
			state.clientWidths = document.documentElement.clientWidth;
		},
		changePlatform(state, val) {
			state.cid = val.cid;
		},
		changeCustomData(state, val) {
			state.customData = val;
			if (val?.theme?.attr || false) {
				for (let keys in val.theme.attr) {
					document.body.style.setProperty("--" + keys, val.theme.attr[keys]);
				}
			}
		},
		changeIncludes(state, val) {
			state.includes = val;
		},
		updateName_logo(state, val) {
			state.name_logo = val;
			if (val.favicon) {
				var link =
					document.querySelector("link[rel*='icon']") ||
					document.createElement("link");
				link.type = "image/x-icon";
				link.rel = "shortcut icon";
				link.href = val.favicon;
				document.getElementsByTagName("head")[0].appendChild(link);
			}
		},

	},
	actions: {
		updatePageNav({ commit }, val) {
			commit('updatePageNav', val)
		},
		loginInfo({ commit }, val) {
			commit('loginInfo', val)
		},
	},
	getters: {
		pageNav: (state) => state.pageNav,
		loginInfo: (state) => state.loginInfo,
	},
	modules: {

	}
})
