// 引入axios
import axios from "axios";
import Qs from "qs";
import { Message, Loading } from "element-ui";
import router from "../router";

import { getFingerprint } from "@/assets/js/finger.js";

// 创建axios实例
let loadingInstance;
const httpService = axios.create({
  baseURL: VUE_APP_BASE_API, // url前缀
  timeout: 30000, // 请求超时时间
  //允许跨域
  // withCredentials:true,
});

let ele_messgae = true;
// request拦截器
httpService.interceptors.request.use(
  async (config) => {
    let urls = config.url;
    if (urls.startsWith("/api")) {
      urls = urls.slice(4);
      config.url = urls;
    }
    if (process.env.NODE_ENV === "production") {
      var strCookies = document.cookie;
      var array = strCookies.split(";");
      for (var i = 0; i < array.length; i++) {
        var item = array[i].split("=");
        if (item[0].indexOf("FeiWinCid") > -1) {
          localStorage.setItem("FeiWinCid", item[1]);
        }
      }
      config.headers["FeiWinCid"] = localStorage.getItem("FeiWinCid");
      if (!config.headers["FeiWinCid"]) {
        if (!localStorage.getItem("reload")) {
          localStorage.setItem("reload", "2");
          window.location.reload();
        } else {
          setTimeout(() => {
            localStorage.removeItem("reload");
          }, 1000);
          return;
        }
      }
    } else {
      config.headers["FeiWinCid"] = 4;
    }

    if (process.env.NODE_ENV === "production") {
      config.headers["Authorization"] = sessionStorage.getItem("AccessToken");
    } else {
      config.headers["Authorization"] = localStorage.getItem("AccessToken");
    }

    let getFingerprints = await getFingerprint();
    config.headers["Sign-Key"] = getFingerprints;

    loadingInstance = Loading.service({
      fullscreen: !0,
      background: "rgba(0,0,0,.1)",
      text: "网络加载...",
    });

    return config;
  },
  (error) => {
    // 请求错误处理
    Promise.reject(error);
  }
);

// respone拦截器
httpService.interceptors.response.use(
  (response) => {
    // 统一处理状态
    const res = response.data;
    loadingInstance && loadingInstance.close();
    if (response.config.url === "/base/file/down") {
      return res;
    }
    if (res.code == 200) {
      // 需自定义
      return response.data;
    } else if (typeof res === "string" && res.indexOf("<!DOCTYPE html>") > -1) {
      return res;
    } else {
      let url = response.config.url;
      // 返回异常
      let msg = res.msg || res.message;
      if (typeof res.msg == "object") {
        for (let m in res.msg) {
          msg = res.msg[m];
        }
        if (msg instanceof Array) {
          msg = msg[0];
        }
      }
      //屏蔽微信配置错误信息
      if (/msg\/jssdkApi/.test(url)) {
        console.log(1);
      } else {
        if (ele_messgae) {
          ele_messgae = false;
          Message({
            message: res.msg || msg,
            type: "error",
            onClose: function() {
              ele_messgae = true;
            },
          });
        }
      }
      return Promise.reject({
        code: res.code,
        data: res.data,
        msg: res.msg || msg,
      });
    }
  },
  // 处理处理
  (error) => {
    loadingInstance.close();
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.msg = "错误请求";
          break;
        case 401:
          error.msg = "未授权，请重新登录";
          sessionStorage.removeItem("AccessToken");
          router.push("/login");
          break;
        case 403:
          error.msg = "拒绝访问";
          break;
        case 404:
          error.msg = "请求错误,未找到该资源";
          break;
        case 405:
          error.msg = "请求方法未允许";
          break;
        case 408:
          error.msg = "请求超时";
          break;
        case 500:
          error.msg = "服务器端出错";
          break;
        case 501:
          error.msg = "网络未实现";
          break;
        case 502:
          error.msg = "网络错误";
          break;
        case 503:
          error.msg = "服务不可用";
          break;
        case 504:
          error.msg = "网络超时";
          break;
        case 505:
          error.msg = "http版本不支持该请求";
          break;
        default:
          error.msg = `未知错误${error.response.status}`;
      }
    } else {
      error.msg = "连接到服务器失败";
    }

    if (ele_messgae) {
      ele_messgae = false;
      Message({
        message: error.msg,
        type: "error",
        onClose: function() {
          ele_messgae = true;
        },
      });
    }
    return Promise.reject(error);
  }
);

/*网络请求部分*/

/*
 *  get请求
 *  url:请求地址
 *  params:参数
 * */
export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    httpService
      .get(url, {
        params: params,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
//delete 请求
export function delt(url, params = {}) {
  return new Promise((resolve, reject) => {
    httpService({
      url: url,
      method: "delete",
      params: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
//put 请求
export function put(url, params = {}) {
  return new Promise((resolve, reject) => {
    httpService({
      url: url,
      method: "put",
      params: params,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

//patch 请求
export function patch(url, params = {}) {
  return new Promise((resolve, reject) => {
    httpService({
      url: url,
      method: "patch",
      data: Qs.stringify(params),
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
/*
 *  post请求
 *  url:请求地址
 *  params:参数
 * */
export function post(url, params = {}) {
  // console.log('post',url,params)
  return new Promise((resolve, reject) => {
    httpService({
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      url: url,
      method: "post",
      data: Qs.stringify(params),
    })
      .then((response) => {
        // console.log('axios res',response)
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/*
 *  post请求
 *  url:请求地址
 *  params:参数
 * */
export function postJSON(url, params = {}) {
  // console.log('post',url,params)
  return new Promise((resolve, reject) => {
    httpService({
      url: url,
      method: "post",
      data: params,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function deltJSON(url, params = {}) {
  return new Promise((resolve, reject) => {
    httpService({
      url: url,
      method: "delete",
      headers: {
        "Content-Type": "application/json",
      },
      data: params,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function putJSON(url, params = {}) {
  return new Promise((resolve, reject) => {
    httpService({
      url: url,
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      data: params,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
//patch 请求
export function patchJSON(url, params = {}) {
  return new Promise((resolve, reject) => {
    httpService({
      headers: {
        "Content-Type": "application/json",
      },
      url: url,
      method: "patch",
      data: params,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/*
 *  文件上传
 *  url:请求地址
 *  params:参数
 * */
export function fileUpload(url, params = {}) {
  return new Promise((resolve, reject) => {
    httpService({
      url: url,
      method: "post",
      data: params,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// 重写文件上传
export function uploadFile(api, object) {
  if (object == null) object = {};
  const param = new FormData();
  for (const key in object) {
    param.append(key, object[key]);
  }
  return new Promise((resolve, reject) => {
    httpService({
      // url: $utils.uploadApi + api,
      url: "https://static." + VUE_APP_API_URL + api,
      method: "post",
      data: param,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function postStatic(url, params = {}) {
  // console.log('post',url,params)
  return new Promise((resolve, reject) => {
    httpService({
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      url: "https://static." + VUE_APP_API_URL + url,
      method: "post",
      data: Qs.stringify(params),
    })
      .then((response) => {
        // console.log('axios res',response)
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function getStatic(url, params = {}) {
  return new Promise((resolve, reject) => {
    httpService
      .get("https://static." + VUE_APP_API_URL + url, {
        params: params,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getDownload(url, params = {}, name) {
  httpService
    .get(url, {
      params: params,
      responseType: "blob",
    })
    .then((response) => {
      const link = document.createElement("a");
      const blob = new Blob([response]);
      link.style.display = "none";
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
}

export const download = function(res, name = "") {
  let blob = new Blob([res], {
    type: "application/vnd.ms-excel",
  });
  let fileName = name + Date.parse(new Date()) + ".xlsx";
  if (window.navigator.msSaveOrOpenBlob) {
    navigator.msSaveBlob(blob, fileName);
  } else {
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(link.href);
  }
};

export default {
  get,
  post,
  put,
  patch,
  delt,
  fileUpload,
  download,
  postJSON,
  deltJSON,
  putJSON,
  patchJSON,
  uploadFile,
  postStatic,
  getStatic,
  getDownload,
};
