import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import VXETable from 'vxe-table'
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/index.css'
import 'vxe-table/lib/style.css'
import { install } from 'feiwin_npm';

import './plugin/vant';

import { looktype } from './utils/mixins';
Vue.mixin(looktype)

Vue.use(ElementUI).use(VXETable).use(install,{
	NODE_ENV: process.env.NODE_ENV,
	VUE_APP_API_URL: VUE_APP_API_URL,
});

Vue.config.productionTip = false

// 引入全局过滤器函数
import * as filters from '@/filters/index.js'
// 循环注册全局过滤器
Object.keys(filters).forEach(key => {
	Vue.filter(key, filters[key])
})


import Axios from './server/ajax.js';
import utils from './utils/index.js'
Vue.prototype.$axios = Axios;
Vue.prototype.$utils = utils;


Vue.directive("btn-throttling", {
  inserted: function (el, binding) {
    el.addEventListener("click", () => {
      // 判断按钮是否可点击
      if (!el.disabled) {
        el.disabled = true;
        setTimeout(() => {
          el.disabled = false;
        }, binding.value || 3000); //默认3秒节流时间
      }
    });
  },
});


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
