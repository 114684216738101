export const hireHall = [
  {
    path: "/hireHall/list",
    name: "hireHallList",
    title:'招聘前台',
    component: () => import("@/views/hireHall/list/list.vue"),
  },
  {
    path: "/hireHall/list/detail",
    name: "hireHallListDetail",
    title:'招聘详情',
    component: () => import("@/views/hireHall/detail/detail.vue"),
  },
];
