export const mySelf = [{
        path: '/mySelf/index',
        name: 'IndexMyself',
        component: () => import('@/views/mySelf/homeIndex/homeIndex.vue'),
    },
    {
        path: '/mySelf/invitedUser',
        name: 'invitedUser',
        component: resolve => require(['@/views/mySelf/invitedUser/index'], resolve)
    },
    {
        path: '/mySelf/invitedUser/invitedLog',
        name: 'invitedUser',
        component: resolve => require(['@/views/mySelf/invitedUser/invitedLog'], resolve)
    }, {
        path: '/mySelf/invitedUser/invitedDetail',
        name: 'invitedUser',
        component: resolve => require(['@/views/mySelf/invitedUser/invitedDetail'], resolve)
    },
    {
        path: '/mySelf/invitedCompany',
        name: 'invitedUser',
        component: resolve => require(['@/views/mySelf/invitedCompany/index'], resolve)
    },
    {
        path: '/mySelf/invitedCompany/invitedLog',
        name: 'invitedCompanyLog',
        component: resolve => require(['@/views/mySelf/invitedCompany/invitedLog/invitedLog'], resolve)
    },
    {
        path: '/mySelf/invitedCompany/invitedDetail',
        name: 'invitedCompanyLog',
        component: resolve => require(['@/views/mySelf/invitedCompany/invitedLog/invitedDetail'], resolve)
    },
    {
        path: '/mySelf/invitedCompany/setInvited',
        name: 'invitedUser',
        component: resolve => require(['@/views/mySelf/invitedCompany/setInvited/setInvited'], resolve)
    },

    {
        path: '/mySelf/account',
        name: 'Account',
        component: resolve => require(['@/views/mySelf/account/account'], resolve)
    },
]