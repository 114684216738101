export const dark = {
    '--themeColor': '#666', // 主题色
    '--hoverColor': '#484848', // 导航hover颜色
    '--choseFont' : '#ffd10b' // 导航选择的颜色
}


export const light = {
    '--themeColor': '#198aff', // 主题色
    '--hoverColor': '#0071e6', // 导航hover颜色
    '--choseFont' : 'rgb(255, 208, 75)' // 导航选择的颜色
}