export const login = [{
        path: '/login',
        name: 'Login',
        component: () => import('@/views/login/loginHome/index.vue')
    }, {
        path: '/loginWx',
        name: 'LoginWx',
        component: () => import('@/views/login/loginWX/loginWX.vue')
    }, // 注册页面
    {
        path: '/resgister',
        name: 'Resgister',
        component: resolve => require(['@/views/login/resgister'], resolve)
    }, {
        path: '/resgister/forget',
        name: 'ResgisterForget',
        component: resolve => require(['@/views/login/loginHome/forgetPwd.vue'], resolve)
    }, {
        path: '/register',
        name: 'Register',
        redirect: '/register/settled',
        component: () => import('@/views/login/register/index.vue'),
        children: [{
            path: '/register/settled',
            name: 'Settled',
            component: resolve => require(['@/views/login/register/settled/index'], resolve)
        }, {
            path: '/register/platform',
            name: 'RegisterPlatform',
            component: resolve => require(['@/views/login/register/platform/index'], resolve)
        }, {
            path: '/register/staff',
            name: 'RegisterStaff',
            component: resolve => require(['@/views/login/register/staff/index'], resolve)
        }]
    },
]