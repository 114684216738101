const shopStore = [
    {
        path: "/shopStore",
        name: "shopStore",
        title: "首页",
        component: () => import("@/views/shopStore/index.vue"),
        children: [
            {
                path: "index",
                name: "index",
                title: "首页",
                component: () => import("@/views/shopStore/index/index.vue"),
            },
            {
                path: "/shopStore/detail",
                name: "detail",
                title: "商品详情",
                component: () => import("@/views/shopStore/detail/detail.vue"),
            },
            {
                path: "/keepalive",
                component: () => import("@/views/shopStore/searchdetail/keepalive.vue"),
                children: [
                    {
                        path: "/search",
                        name: "search",
                        title: "搜索",
                        component: () => import("@/views/shopStore/search/search.vue"),
                    },
                    {
                        path: "/contrast",
                        name: "contrast",
                        title: "对比",
                        component: () => import("@/views/shopStore/searchdetail/contrast/contrast.vue"),
                    },
                ],
            },
            {
                path: "/shopStore/categoryList",
                name: "categoryList",
                title: "商品列表",
                component: () => import("@/views/shopStore/categoryList/index.vue"),
            }
            
        ]
    },
    {
        path: "/shopStore/submitOrder",
        name: "submitOrder",
        title: "提交订单",
        component: () => import("@/views/shopStore/submitOrder/index.vue"),
    },
    {
        path: "/shopStore/submitOrder/agree",
        name: "agree",
        title: "平台协议",
        component: () => import("@/views/shopStore/submitOrder/agree.vue"),
    },

];
export default shopStore;