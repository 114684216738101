export const shortlist = [{
    path: '/shortlistSubmit',
    name: 'shortlistSubmit',
    component: () => import('@/views/shortlist/shortlistSubmit/index.vue'),
},
{
    path: '/information',
    name: 'information',
    component: () => import('@/views/shortlist/shortlistSubmit/information.vue'),
},
{
    path: '/information/qua',
    name: 'qua',
    component: () => import('@/views/shortlist/shortlistSubmit/qua.vue'),
},
{
    path: '/information/status',
    name: 'status',
    component: () => import('@/views/shortlist/shortlistSubmit/status.vue'),
},
{
    path: '/information/look',
    name: 'look',
    component: () => import('@/views/shortlist/shortlistSubmit/look.vue'),
},
{
    path: '/information/xieYi',
    name: 'xieYi',
    component: () => import('@/views/shortlist/shortlistSubmit/xieYi.vue'),
},

]