window.getFingerprintId = "";
export async function getFingerprint() {
  if (window.getFingerprintId) {
    savePassWrodes(window.getFingerprintId);
  }
  // 初始化FingerprintJS
  const fp = await FingerprintJS.load();
  // 获取访问者的指纹
  const result = await fp.get();
  // 配置
  const {
    osCpu, // 操作系统
    webGlBasics, // wbgbl基本信息
    languages,  // 浏览器语言
    audioBaseLatency, // 音频的基本设置信息
    reducedTransparency, // 透明度
    vendor, // 浏览器供应商信息
    vendorFlavors, // 浏览器特定标识
    fonts, // 字体
    fontPreferences, // 字体偏好
    plugins, // 浏览器插件
    forcedColors, // 浏览器是否开启强制颜色配置
    domBlockers, // 是否开启浏览器广告阻拦
    pdfViewerEnabled, // 是否启用浏览器内置查看pdf
    audio, // 音频处理
    canvas, // 画布信息
    webGlExtensions,  // wbgl的扩展信息
    math, // 数学函数
    ...components
  } = result.components;
  const extendedComponents = {
    osCpu,
    webGlBasics,
    webGlExtensions,
    vendor,
    vendorFlavors,
    ...components,
  };
  const fingerprintId = FingerprintJS.hashComponents(extendedComponents);
  window.getFingerprintId = fingerprintId;
  //   console.log("extendedComponents*-*-*-*-*-*-*-         ", extendedComponents);
  //   console.log("fingerprintId*-*-*-*-*-*-*-         ", fingerprintId);
  return savePassWrodes(fingerprintId);
}
function savePassWrodes(val) {
  //   let unicode = val.split("").reverse().join("");
  let unicode = val;
  const timers = new Date().getTime().toString();
  for (var i = 0; i < timers.length; i++) {
    unicode = unicode.slice(0, i * 2) + timers[i] + unicode.slice(i * 2);
  }
  return unicode;
}
export default getFingerprint;
