<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
const defaultColor = {
  primary: "#409EFF", // 默认颜色
  success: "#67C23A", // 成功色
  warning: "#E6A23C", // 警告色
  danger: "#F56C6C", // 危险色
  info: "#909399", // 信息色
  textPrimary: "#303133", // 默认字体色
  textRegular: "#606266", // 规律字体色
  textSecondary: "#909399", // 次要字体色
  textPlaceholder: "#C0C4CC", // 输入框提示字体色
  borderBase: "#DCDFE6", // 一级边框色
  borderLight: "#E4E7ED", // 二级边框色
  borderLighter: "#EBEEF5", // 三级边框色
  borderExtraLight: "#F2F6FC", // 四级边框色
  white: "#FFFFFF", // 一级背景色
  black: "#000000", // 二级背景黑色
  backgroundBase: "#F5F7FA", // 头部背景色
};
import {
  custHomeGetData,
  name_logo,
  company_detail,
} from "@/assets/api/index.js";
export default {
  created() {
    // 获取是否为保密设备
    this.$axios.postJSON('/base/baomi/check').then((res) => {
      if(res.data === 'ok') {
        this.$store.commit("changeFinger", true);
      }
    })
    let isApp = this.isMobile();
    console.log(isApp);
    if (isApp) {
      // var oMeta = document.createElement("meta");
      // oMeta.content = "user-scalable=0";
      // oMeta.name = "viewport";
      // document.getElementsByTagName("head")[0].appendChild(oMeta);
      // document.body.setAttribute("app", "true");
    }
    // 储存获取主题色
    if (localStorage.getItem("themeColor")) {
      this.$store.commit("changeThemes", localStorage.getItem("themeColor"));
    } else {
      localStorage.setItem("themeColor", "1");
      this.$store.commit("changeThemes", localStorage.getItem("themeColor"));
    }

    // 获取cookie
    var strCookies = document.cookie;
    var array = strCookies.split(";");
    var WebCid = 0;
    for (var i = 0; i < array.length; i++) {
      var item = array[i].split("=");
      if (item[0].indexOf("WebCid") > -1) {
        WebCid = item[1];
        localStorage.setItem("WebCid", item[1]);
      }
    }
    if (WebCid == 0) {
      localStorage.setItem("WebCid", 0);
    }
    // 平台名称和Logo
    this.$axios.get("/setting/platform/name_logo").then((res) => {
      sessionStorage.setItem('platform_name_logo', JSON.stringify(res.data))
      this.setLogoImg(res);
    })
  },
  methods: {
    isMobile() {
      var userAgentInfo = navigator.userAgent;
      var mobileAgents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
      var mobile_flag = false;
      for (var v = 0; v < mobileAgents.length; v++) {
        if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
          mobile_flag = true;
          break;
        }
      }
      var screen_width = window.screen.width;
      var screen_height = window.screen.height;
      //根据屏幕分辨率判断是否是手机
      if (screen_width < 500 && screen_height < 800) {
        mobile_flag = true;
      }
      return mobile_flag;
    },
    // 设置平台的logo和图片等等
    setLogoImg(res) {
      let data = res.data;
      if (!data || !data.value) {
        return false;
      }
      let platInfo = JSON.parse(JSON.stringify(data.value.platform));
      platInfo.favicon = data.value.favicon;
      platInfo.logo_url = data.value.logo.img_url;
      platInfo.logo_ratio = data.value.logo.ratio;
      platInfo.time = +new Date();
      localStorage.platInfo = JSON.stringify(platInfo);
      let $favicon = document.querySelector('link[rel="icon"]');
      if ($favicon !== null) {
        $favicon.href = platInfo.favicon;
      } else {
        $favicon = document.createElement("link");
        $favicon.rel = "icon";
        $favicon.href = platInfo.favicon;
        document.head.appendChild($favicon);
      }
      document.title = platInfo.name; // 动态修改网站标题
      function SeoUpdate(SeoTitle, SeoKeywords, SeoDescription) {
        let _headDom = "",
          _title = "",
          _meta = "";
        _headDom = document.getElementsByTagName("head")[0]; //获取head节点
        _title = _headDom.getElementsByTagName("title")[0]; //获取head节点下的title节点
        _meta = _headDom.getElementsByTagName("meta"); //获取head节点下的meta节点，它一般是一个数组
        for (let index = 0; index < _meta.length; index++) {
          switch (_meta[index].name) {
            case "keywords":
              _meta[index].content = SeoKeywords;
              break;
            case "description":
              _meta[index].content = SeoDescription;
              break;

            default:
              break;
          }
        }
      }
      SeoUpdate(platInfo.name, platInfo.keywords, platInfo.description);
    },
  },
};
</script>

<style>
html,
body,
#app {
  background-color: #f5f5f5;
  font-size: 14px;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  color: #333;
}
body::-webkit-scrollbar {
  display: none;
}

.cursor {
  cursor: pointer;
}
</style>

<style lang="scss">
body[app] {
  .el-message {
    font-size: 1.4rem;
    border-radius: 0.2rem;
    top: 2rem !important;
    .el-message__content {
      font-size: 1.2rem;
    }
  }
}
.mytable-scrollbar ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
/*滚动条的轨道*/
.mytable-scrollbar ::-webkit-scrollbar-track {
  background-color: #ffffff;
}
/*滚动条里面的小方块，能向上向下移动*/
.mytable-scrollbar ::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  border-radius: 5px;
  border: 1px solid #f1f1f1;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.mytable-scrollbar ::-webkit-scrollbar-thumb:hover {
  background-color: #a8a8a8;
}
.mytable-scrollbar ::-webkit-scrollbar-thumb:active {
  background-color: #787878;
}
/*边角，即两个滚动条的交汇处*/
.mytable-scrollbar ::-webkit-scrollbar-corner {
  background-color: #ffffff;
}

.konggefu {
  display: inline-block;
  width: 7px;
}
.chongzhiPlain {
  margin: 0 13px;
  .is-plain {
    border-color: #409eff !important;
    color: #409eff !important;
    &:hover {
      background-color: #409eff !important;
      color: #fff !important;
    }
  }
}

.ellipsisText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.duohangEllipsisText {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  max-height: 76px; //  3行的时候哈
}

.duohangEllipsisTextTwo {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 52px; //  2行的时候哈
}

.empha {
  cursor: pointer;
  color: #2d8cf0;
}
.DrowJianyan{
  .el-container{
    height: 100%;
  }
}
</style>
