import axios from '../server/ajax.js';
import router from '../router'
import {
  Loading,
  Message
} from 'element-ui';
const utils = {
  regPhone: (value) => {
    return /^1[3-9]\d{9}$/.test(value)
  },
  isEmpty(data) {
    if (data === null || data === undefined) {
      return true;
    } else if (data instanceof Array && data.length == 0) {
      return true;
    } else if (data instanceof Object && Object.keys(data).length === 0) {
      return true;
    } else {
      return false;
    }
  },
  ossImgUrl: 'https://static.' + VUE_APP_API_URL,
  imgUrl: 'https://avatar.' + VUE_APP_API_URL,
  webUrl(url, black) {
    let href = url.split('/#');
    if (black) {
      if (/^www/.test(url)) {
        window.open('https://' + url, '_blank');
      } else if (/^(http:|https:)/.test(url)) {
        window.open(url, '_blank');
      } else if (href[1]) {
        let url = router.resolve(href[1]);
        window.open(url.href, '_blank');
      } else {
        let routerUrl = router.resolve(url);
        window.open(routerUrl.href, '_blank');
      }
    } else {
      if (/^(http:|https:)/.test(url)) {
        setTimeout(() => {
          location.href = url;
        }, 100)
      } else if (/^www/.test(url)) {
        location.href = 'https://' + url;
      } else if (href[1]) {
        router.push(href[1]);
      } else {
        router.push(url);
      }
    }
  },
  getQrcode(data) {
    return new Promise((resolve, reject) => {
      // axios.post(process.env.VUE_APP_BASE_API +'/ocr/generate_qrcode',data).then(res=>{
      axios.post('https://api.testfw.cn/ocr/generate_qrcode', data).then(res => {
        if (res.code == 200) {
          resolve({
            img: res.data
          })
        } else {
          reject({
            msg: "获取图片失败"
          })
        }
      }).catch(err => {
        console.log(err);
        reject({
          msg: "获取图片失败"
        })
      })
    })
  },
  goIndexPage() {
    let _this = this;
    if (sessionStorage.logo_url) {
      utils.webUrl(sessionStorage.logo_url)
    } else {
      axios.get("/setting/public/logo_link").then(res => {
        if (res.code == 200) {
          let data = res.data;
          if (data && data.url) {
            sessionStorage.logo_url = data.url;
            utils.webUrl(data.url)
          }
        }
      })
    }
  },
  regMobile(val) {
    return /^1[3-9]\d{9}$/.test(val)
  },
  setWebsiteInfo() {
    axios.get("/setting/platform/name_logo").then((res) => {
      if (res.code == 200) {
        let data = res.data;
        if (!data || !data.value) {
          return false;
        }
        this.platform = data.value;
        let platInfo = JSON.parse(JSON.stringify(data.value.platform));
        platInfo.favicon = data.value.favicon;
        platInfo.logo_url = data.value.logo.img_url;
        platInfo.logo_ratio = data.value.logo.ratio;
        platInfo.time = +new Date();
        localStorage.platInfo = JSON.stringify(platInfo);
        let $favicon = document.querySelector('link[rel="icon"]');
        if ($favicon !== null) {
          $favicon.href = platInfo.favicon;
        } else {
          $favicon = document.createElement("link");
          $favicon.rel = "icon";
          $favicon.href = platInfo.favicon;
          document.head.appendChild($favicon);
        }
        document.title = platInfo.name; // 动态修改网站标题
        function SeoUpdate(SeoTitle, SeoKeywords, SeoDescription) {
          let _headDom = '',
            _title = '',
            _meta = '';
          _headDom = document.getElementsByTagName('head')[0]; //获取head节点
          _title = _headDom.getElementsByTagName("title")[0]; //获取head节点下的title节点
          _meta = _headDom.getElementsByTagName("meta"); //获取head节点下的meta节点，它一般是一个数组
          for (let index = 0; index < _meta.length; index++) {
            switch (_meta[index].name) {
              case 'keywords':
                _meta[index].content = SeoKeywords;
                break;
              case 'description':
                _meta[index].content = SeoDescription;
                break;

              default:
                break;
            }
          }
        }
        SeoUpdate(platInfo.name, platInfo.keywords, platInfo.description)
      }
    })
  },
  downFiles(val) {
    axios.get('/base/file/down', {
      params: {
        attachment_id: val.attachment_id,
        model_name: "123",
      },
      responseType: 'blob'
    }).then(response => {
      const link = document.createElement("a");
      const blob = new Blob([response]);
      link.style.display = "none";
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }).catch(error => {
      reject(error);
    });
  },
  getPlatformInfo() {
    function getRemoveData(resolve, reject) {
      axios.get("/setting/platform/name_logo").then((res) => {
        if (res.code == 200) {
          let data = res.data;
          if (!data || !data.value) {
            return false;
          }
          this.platform = data.value;
          let platInfo = JSON.parse(JSON.stringify(data.value.platform));
          platInfo.favicon = data.value.favicon;
          platInfo.logo_url = data.value.logo.img_url;
          platInfo.logo_ratio = data.value.logo.ratio;
          platInfo.time = +new Date();
          localStorage.platInfo = JSON.stringify(platInfo);
          resolve({
            data: platInfo
          })
        } else {
          reject({
            msg: "获取平台信息失败"
          })
        }
      })
    }
    return new Promise((resolve, reject) => {
      if (localStorage.platInfo) {
        let dayTime = 8.64e7,
          hour = 3.6e6;
        let platInfo = JSON.parse(localStorage.platInfo),
          now = new Date().getTime();
        if (now - platInfo > 0.1 * hour) {
          getRemoveData(resolve, reject)
        } else {
          resolve({
            data: JSON.parse(localStorage.platInfo)
          });
        }
      } else {
        getRemoveData(resolve, reject)
      }
    })
  },
  cloneDeep(data) {
    return JSON.parse(JSON.stringify(data))
  },
  domainWeb() {
    location.href = "https://www." + document.domain.split('.').slice(-2).join('.')
  },
  hideLeadingZeros(input) {
    if (input) {
      // 如果输入不是字符串，则将其转换为字符串
      if (typeof input !== "string") {
        input = input.toString();
      }
      // 使用正则表达式替换字符串开头的所有零
      return input.replace(/^0+/, "");
    } else {
      return input
    }
  }
}
export default utils